import React from "react";
import "../../Assets/css/case.css";
import "../../Assets/css/base.css";
import "../../Assets/css/demo1.css";
import "../../Assets/css/infinitscroller.css";
import "../../Assets/css/artisans.css";
import ReactPlayer from "react-player";
import Parallax from "react-rellax";
import $ from "jquery";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Header from "../../components/header";
import Footer from "../../components/footer";
import SEO from "../../components/seo";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
if (typeof window !== "undefined") {
  require("jquery-simplyscroll");
}

class Artisans extends React.Component {
  componentDidMount() {
    gsap.to(".pinned", {
      scrollTrigger: {
        trigger: ".pinned",
        start: "top center",
        eend: "20px 80%",
        pin: true,
        scrub: true,
        markers: true,
      },
      duration: 1,
      x: 400,
      duration: 1,
    });

    require("paroller.js");
    $(document).ready(function () {
      $(".paroller-example").paroller({
        factor: 0.15,
        type: "foreground",
        direction: "vertical",
      });
    });

    $("#scroller").simplyScroll({
      auto: true,
      autoMode: "loop",
      customClass: "scroll",
      direction: "backward",
      frameRate: 60,
      initialOffset: 0,
      manualMode: "end",
      orientation: "horizontal",
      pauseButton: false,
      pauseOnHover: false,
      pauseOnTouch: false,
      speed: 8,
      startOnLoad: false,
    });

    $("#scroller2").simplyScroll({
      auto: true,
      autoMode: "loop",
      customClass: "scroll",
      direction: "forwards",
      frameRate: 60,
      initialOffset: 0,
      manualMode: "end",
      orientation: "horizontal",
      pauseButton: false,
      pauseOnHover: false,
      pauseOnTouch: false,
      speed: 3,
      startOnLoad: false,
    });

    $("#scroller3").simplyScroll({
      auto: true,
      autoMode: "loop",
      customClass: "scrollxl",
      direction: "backward",
      frameRate: 60,
      initialOffset: 0,
      manualMode: "end",
      orientation: "horizontal",
      pauseButton: false,
      pauseOnHover: false,
      pauseOnTouch: false,
      speed: 1,
      startOnLoad: false,
    });
  }

  UNSAFE_componentWillMount() {}

  render() {
    return (
      <div>
        <Header></Header>
        <SEO
          title="Driha Apps · A new take on the common type of apps ( to-do apps )"
          description="Project management, web editorial, advanced SEO and
                      Net-linking on a single platform"
          image={
            "https://makook.space" +
            require("../../images/Dirha/Dirha-cover.jpg")
          }
        />

        <div
          data-post-title="makookcase"
          data-theme="theme-dark"
          className="theme-dark"
        >
          <main className="case makookcase">
            <h1 className="master-title">Artisans</h1>

            <div
              className="background-color-makookcase-1 color-transition"
              data-color="#000"
            >
              <section className="image-block sticky-title-padding-top mobile-hero no-margin-bottom sticky-stop">
                <figure>
                  <Img fluid={this.props.data.imageOne.childImageSharp.fluid} />
                  <Parallax speed={-2} percentage={0.5} centered>
                    <p className=" desktop-only">
                      A new take on the common ecommerce type of apps
                    </p>
                  </Parallax>

                  <Parallax speed={-2} percentage={0.5} centered>
                    <p className="mobile-only">
                      A new take on the common ecommerce type of apps
                    </p>
                  </Parallax>
                </figure>
              </section>

              <section className="image-text push-up half-padding-bottom no-margin-bottom">
                <figure>
                  <Img fluid={this.props.data.imageTwo.childImageSharp.fluid} />
                </figure>
                <article>
                  <span>Date</span>
                  <p>2020 – 2021</p>
                  <span>Challenge</span>
                  <p>
                    Branding, marketing, inventory management, shipping and
                    online payments. These are some of the challenges that
                    Algerian artisans (Hirafi) and non tech savvy small business
                    owners face on a daily basis. Where some excel to market
                    their products on social media, most of them depend on local
                    clientele as their main source of income which is slowly
                    fading away.
                    <br></br>
                    That raised the question, Can we build a solution that
                    adresses the struggles artisans face, a solution that is
                    both uniquely Algerian and yet can function internationally.
                  </p>
                  <span>Solution</span>
                  <p>
                    With a goal set in stone, we started the process of
                    developing our platform by embracing the core values of our
                    Hirafis (artisans) and letting their products be the hero.
                    With any com­merce driven platform it is also of high­est
                    im­por­tance that the app is as fric­tion­less as pos­sible
                    for the end user as well as for the business and their
                    op­e­ration.
                    <br></br>
                    That is why we work­ed hard to both make it as easy as
                    pos­sible for an end user to find and buy what they want as
                    well as giving the multitude of choices regarding the
                    in­te­grat­ion of the retail system used in store, in order
                    to create a seam­less ex­pe­ri­ence for the Artisan.
                  </p>
                  <span>Disciplines</span>
                  <p>
                    Brand Identity
                    <br />
                    Design System
                    <br />
                    User Experience
                    <br />
                    User Research
                    <br />
                    Prototyping
                    <br />
                    System Development
                    <br />
                    API Development
                    <br />
                    System Integration
                    <br />
                    IOS App dev
                    <br />
                    Android App dev
                    <br />
                  </p>
                </article>
              </section>

              <section className="mobile-text mobile-only">
                <article>
                  <div>
                    <span>Date</span>
                    <p>2020 – 2021</p>
                  </div>
                  <div>
                    <span>Challenge</span>
                    <p>
                      Branding, marketing, inventory management, shipping and
                      online payments. These are some of the challenges that
                      Algerian artisans (Hirafi) and non tech savvy small
                      business owners face on a daily basis. Where some excel to
                      market their products on social media, most of them depend
                      on local clientele as their main source of income which is
                      slowly fading away.
                      <br></br>
                      That raised the question, Can we build a solution that
                      adresses the struggles artisans face, a solution that is
                      both uniquely Algerian and yet can function
                      internationally.
                    </p>
                    <span>Solution</span>
                    <p>
                      With a goal set in stone, we started the process of
                      developing our platform by embracing the core values of
                      our Hirafis (artisans) and letting their products be the
                      hero. With any com­merce driven platform it is also of
                      high­est im­por­tance that the app is as fric­tion­less as
                      pos­sible for the end user as well as for the business and
                      their op­e­ration.
                      <br></br>
                      That is why we work­ed hard to both make it as easy as
                      pos­sible for an end user to find and buy what they want
                      as well as giving the multitude of choices regarding the
                      in­te­grat­ion of the retail system used in store, in
                      order to create a seam­less ex­pe­ri­ence for the Artisan.
                    </p>
                  </div>
                  <div>
                    <span>Disciplines</span>
                    <p>
                      Brand Identity
                      <br />
                      Design System
                      <br />
                      User Experience
                      <br />
                      User Research
                      <br />
                      Prototyping
                      <br />
                      System Development
                      <br />
                      API Development
                      <br />
                      System Integration
                    </p>
                  </div>
                </article>
              </section>
            </div>

            <div
              className="background-color-makookcase-2 color-transition"
              data-color="#1b1b1b"
            >
              <section>
                <div className="pics-container">
                  <div className="parent">
                    <div className="parent-img">
                      <figure>
                        <Img
                          className="pic-001"
                          alt="Artisans Home Screen"
                          fluid={this.props.data.home.childImageSharp.fluid}
                        />
                      </figure>
                    </div>
                    <div className="child-img-01">
                      <figure>
                        <Img
                          className="single-product"
                          alt="product details"
                          fluid={
                            this.props.data.singleProduct.childImageSharp.fluid
                          }
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="parent">
                    <div className="parent-img">
                      <figure className="pic-002">
                        <Img
                          alt="Artisans Home Screen"
                          fluid={
                            this.props.data.ProductDetails.childImageSharp.fluid
                          }
                        />
                      </figure>
                    </div>
                    <div className="child-img-02">
                      <figure>
                        <Img
                          className="product-details"
                          alt="Artisans Home Screen"
                          fluid={
                            this.props.data.ProductInfos.childImageSharp.fluid
                          }
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="parent">
                    <div className="parent-img">
                      <figure className="pic-003">
                        <Img
                          alt="Profile"
                          fluid={this.props.data.Profile.childImageSharp.fluid}
                        />
                      </figure>
                    </div>
                    <div className="child-img-03">
                      <figure>
                        <Img
                          className="shipping-address"
                          alt="Shipping address"
                          fluid={this.props.data.Shipping.childImageSharp.fluid}
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </section>

              <section className="no-padding">
                <div className="scroll scroll-container">
                  <div className="scroll-clip">
                    <ul id="scroller" className="scroll-list">
                      <li>
                        <figure>
                          <img
                            src={require("../../images/artisans/light/buttons.png")}
                            alt="episode"
                          />
                        </figure>
                      </li>
                      <li>
                        <figure>
                          <img
                            src={require("../../images/artisans/light/Email-input.png")}
                            alt="episode"
                          />
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/artisans/light/Email-input.png")}
                            alt="episode"
                          />
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/artisans/light/icon-input.png")}
                            alt="episode"
                          />
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/artisans/light/password-input.png")}
                            alt="episode"
                          />
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/artisans/light/Phone-number-input.png")}
                            alt="episode"
                          />
                        </figure>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="scroll scroll-container no-padding">
                  <div className="scroll-clip ">
                    <ul id="scroller2" className="scroll-list">
                      <li>
                        <figure>
                          <img
                            src={require("../../images/artisans/dark/dark-buttons.png")}
                            alt="episode"
                          />
                        </figure>
                      </li>
                      <li>
                        <figure>
                          <img
                            src={require("../../images/artisans/dark/Email-input.png")}
                            alt="episode"
                          />
                        </figure>
                      </li>
                      <li>
                        <figure>
                          <img
                            src={require("../../images/artisans/dark/Name-input.png")}
                            alt="episode"
                          />
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/artisans/dark/password-input.png")}
                            alt="episode"
                          />
                        </figure>
                      </li>

                      <li>
                        <figure>
                          <img
                            src={require("../../images/artisans/dark/Phone-number-input.png")}
                            alt="episode"
                          />
                        </figure>
                      </li>
                    </ul>
                  </div>
                </div>
                <div></div>
                <div className="scrollxl scroll-container">
                  <div className="scrollxl scroll-container no-padding">
                    <div className="scroll-clip ">
                      <ul id="scroller3" className="scroll-list">
                        <li>
                          <figure>
                            <img
                              src={require("../../images/artisans/dark/Payment-method.png")}
                              alt="episode"
                            />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img
                              src={require("../../images/artisans/light/Payment-method.png")}
                              alt="episode"
                            />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img
                              src={require("../../images/artisans/dark/Shipping-adress.png")}
                              alt="episode"
                            />
                          </figure>
                        </li>

                        <li>
                          <figure>
                            <img
                              src={require("../../images/artisans/light/Shipping-adress.png")}
                              alt="episode"
                            />
                          </figure>
                        </li>

                        <li>
                          <figure>
                            <img
                              src={require("../../images/artisans/dark/cart-product.png")}
                              alt="episode"
                            />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img
                              src={require("../../images/artisans/light/cart-product.png")}
                              alt="episode"
                            />
                          </figure>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <div className="rallax-container">
                  <Parallax speed={4} percentage={0.5} centered>
                    <div className="parent-img">
                      <figure className="padding-figure">
                        <Img
                          alt="Profile"
                          fluid={this.props.data.Signup.childImageSharp.fluid}
                        />
                      </figure>

                      <figure className="padding-figure">
                        <Img
                          alt="Profile"
                          fluid={this.props.data.HomeDark.childImageSharp.fluid}
                        />
                      </figure>
                    </div>
                  </Parallax>

                  <Parallax speed={-2} percentage={1} centered>
                    <div className="parent-img">
                      <figure className="padding-figure">
                        <Img
                          alt="Profile"
                          fluid={this.props.data.Cart.childImageSharp.fluid}
                        />
                      </figure>
                      <figure className="padding-figure">
                        <Img
                          alt="Profile"
                          fluid={this.props.data.Discover.childImageSharp.fluid}
                        />
                      </figure>
                    </div>
                  </Parallax>
                  <Parallax speed={1} percentage={1.5} centered>
                    <div className="parent-img">
                      <figure className="padding-figure">
                        <Img
                          alt="Profile"
                          fluid={
                            this.props.data.DarkProfile.childImageSharp.fluid
                          }
                        />
                      </figure>
                      <figure className="padding-figure">
                        <Img
                          alt="Profile"
                          fluid={
                            this.props.data.ShippingAdres.childImageSharp.fluid
                          }
                        />
                      </figure>
                    </div>
                  </Parallax>
                </div>
              </section>

              <section className="image-block half-margin-bottom ">
                <div className="phone-container">
                  <h3 className="paroller-example hero-text desktop-only">
                    A streamlined user experience across platforms with
                    persona-based interfaces and behaviour-changing
                    functionality.
                  </h3>
                  <figure className="h-image pinned">
                    <Img
                      fluid={this.props.data.homepage.childImageSharp.fluid}
                    />
                  </figure>
                </div>
              </section>

              <section className="image-block half-margin-bottom">
                <figure>
                  <Img
                    fluid={this.props.data.imageFour.childImageSharp.fluid}
                  />
                </figure>
              </section>

              <section className="makookcase-block-1">
                <h3 className="paroller-example paroller-title desktop-only">
                  A streamlined user experience across platforms with
                  persona-based interfaces and behaviour-changing functionality.
                </h3>
              </section>

              <section className="image-block">
                <figure className="">
                  <Img
                    fluid={this.props.data.imageFive.childImageSharp.fluid}
                  />
                </figure>
              </section>

              <section className="image-text half-margin-bottom">
                <figure>
                  <Img fluid={this.props.data.imageSix.childImageSharp.fluid} />

                  <Parallax speed={2}>
                    <h3 className=" mobile-only">
                      An honest and purposeful design system that allows users
                      to focus on what’s most important — getting things done.
                    </h3>
                  </Parallax>
                </figure>
                <article>
                  <Parallax speed={-2} percentage={0.5} centered>
                    <h3 className=" desktop-only" data-func="caseIntroParallax">
                      An honest and purposeful design system that allows users
                      to focus on what’s most important — getting things done.
                    </h3>
                  </Parallax>
                </article>
              </section>

              <section className="image-block">
                <figure className="">
                  <ReactPlayer
                    className=""
                    url={require("../../images/makook-dirha-darkmode.mp4")}
                    width="100%"
                    height="100%"
                    playing
                    muted
                    loop
                  />
                </figure>
              </section>

              <Footer />
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default Artisans;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1400, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const ArtisansQuery = graphql`
  query {
    home: file(relativePath: { eq: "artisans/HomeScreen.jpg" }) {
      ...fluidImage
    }

    singleProduct: file(relativePath: { eq: "artisans/singleProduct.jpg" }) {
      ...fluidImage
    }
    ProductDetails: file(relativePath: { eq: "artisans/Product-details.jpg" }) {
      ...fluidImage
    }
    ProductInfos: file(relativePath: { eq: "artisans/Product-infos.jpg" }) {
      ...fluidImage
    }

    Profile: file(relativePath: { eq: "artisans/Profile.jpg" }) {
      ...fluidImage
    }

    Shipping: file(relativePath: { eq: "artisans/Shipping.png" }) {
      ...fluidImage
    }

    Discover: file(relativePath: { eq: "artisans/dark/Discover-dark.jpg" }) {
      ...fluidImage
    }
    DarkProfile: file(
      relativePath: { eq: "artisans/dark/Profile-screen.jpg" }
    ) {
      ...fluidImage
    }

    HomeDark: file(relativePath: { eq: "artisans/dark/home-screen-dark.jpg" }) {
      ...fluidImage
    }

    AddCreditCard: file(
      relativePath: { eq: "artisans/dark/Profile-credit-card.jpg" }
    ) {
      ...fluidImage
    }

    Cart: file(relativePath: { eq: "artisans/dark/Cart-screen.jpg" }) {
      ...fluidImage
    }

    ShippingAdres: file(relativePath: { eq: "artisans/dark/add-adress.jpg" }) {
      ...fluidImage
    }
    Signup: file(relativePath: { eq: "artisans/dark/signup.jpg" }) {
      ...fluidImage
    }
    homepage: file(relativePath: { eq: "artisans/Homepage.jpg" }) {
      ...fluidImage
    }

    imageOne: file(relativePath: { eq: "Dirha/Dirha-cover.jpg" }) {
      ...fluidImage
    }

    imageTwo: file(relativePath: { eq: "Dirha/Dirha-branding.jpg" }) {
      ...fluidImage
    }

    imageThree: file(relativePath: { eq: "Dirha/Dirha-3-screens.jpg" }) {
      ...fluidImage
    }

    imageFour: file(relativePath: { eq: "Dirha/caleder-menu.jpg" }) {
      ...fluidImage
    }

    imageFive: file(relativePath: { eq: "Dirha/ipad-pro.jpg" }) {
      ...fluidImage
    }

    imageSix: file(relativePath: { eq: "Dirha/dirha-dark-mode.png" }) {
      ...fluidImage
    }
  }
`;
